import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { stringify } from 'query-string'
import Layout from '../components/Layout'
import { useModal } from '../hooks/modal'
import Button from '../components/common/Button'
import { useInfiniteScroll, useLoad } from '../hooks/request'
import { BRANCH_LIST, CUSTOMER_LIST, EMPLOYEE_LIST, GROUPS_LIST, STATUS_LIST, SUBJECT_LIST } from '../urls'
import Table from '../components/common/Table'
import { useQueryParams } from '../hooks/queryString'
import MessageCreate from '../components/MessageCreate'
import StudentMessageItem from '../components/StudentMessageItem'
import Loader from '../components/common/Loader'
import AcademyHeader from '../components/AcademyHeader'
import { checkPermission as cp } from '../utils/auth'
import { PermissionDenied } from '../components/PermissionDenied'
import FilterSelect from '../components/common/FilterSelect'
import SearchInput from '../components/common/SearchInput'

export default function Message() {
    const params = useQueryParams()
    const customers = useInfiniteScroll({ url: CUSTOMER_LIST, params: { ...params } })
    const customersList = customers.response?.results || []
    const [checkedItems, setCheckedItems] = useState([])

    const [showCreateModal, hideCreateModal] = useModal(
        <MessageCreate groupId={params.group} type={params.type} onSuccess={() => {
            hideCreateModal()
            customers.request()
        }} onCancel={() => hideCreateModal()} />,
    )

    const history = useHistory()

    function filterByType(type) {
        const newParams = { ...params, type }
        delete newParams.lesson_count // Remove lesson_count when type filter is applied
        history.push(`?${stringify(newParams)}`)
    }

    function filterByLessonCount(lesson_count) {
        const newParams = { ...params, lesson_count }
        delete newParams.type // Remove lesson_count when type filter is applied

        history.push(`?${stringify(newParams)}`)
    }

    const status = useLoad({ url: STATUS_LIST })
    const statusList = status.response?.results || []

    const branch = useLoad({ url: BRANCH_LIST })
    const branchItems = branch.response ? branch.response : []

    const group = useLoad({ url: GROUPS_LIST })
    const groupItems = group.response?.results || []

    const teachers = useLoad({ url: EMPLOYEE_LIST, params: { positions: 'teacher', size: 1000 } })
    const teachersOptions = teachers.response?.results || []

    const subject = useLoad({url:SUBJECT_LIST})
    const subjectList = subject.response?.results || []

    if (!cp('academy.view_customer')) {
        return <PermissionDenied />
    }

    return (
        <Layout>
            <AcademyHeader />

            <main style={{ display: 'flex', alignItems: 'center', gap: '1rem', flexWrap: 'wrap', justifyContent: 'space-between', marginBottom:'1rem' }}>
                <div style={{ display: 'flex', gap: '1rem', flexWrap: 'wrap' }}>
                    <a onClick={() => filterByType('')}
                        className={cn('tag', { 'light-primary': params.type === '' })}>

                        <i className="icon ion-ios-people" />
                        Все клиенты
                    </a>

                    <a onClick={() => filterByType('active')}
                        className={cn('tag', { 'light-info': !params.type || params.type === 'active' })}>

                        <i className="icon ion-ios-flame" />
                        Активные
                    </a>

                    <a onClick={() => filterByType('rejected')}
                        className={cn('tag', { 'light-danger': params.type === 'rejected' })}>

                        <i className="icon ion-ios-warning" />
                        Отказавшиеся
                    </a>

                    <a onClick={() => filterByType('archived')}
                        className={cn('tag', { 'light-gray': params.type === 'archived' })}>

                        <i className="icon ion-ios-filing" />
                        Архив
                    </a>

                    <a
                        onClick={() => filterByLessonCount(1)}
                        className={cn('tag', { 'light-info': params.lesson_count === 1 })}
                    >
                        <i className="icon ion-ios-cash" />
                        Должники
                    </a>
                </div>

                <aside style={{ display:'grid',
                            gridTemplateColumns:'repeat(auto-fill, minmax(12rem, auto))',
                            gap:'1rem',
                            width:'100%',
                            marginBottom:'2rem' }}
                >
                    <FilterSelect name={'subject'} options={subjectList} placeholder='Предмет'/>
                    <FilterSelect name={'state'} optionLabelKey='title' options={statusList} placeholder='Состояние'/>
                    {/*<FilterSelect name={'company'} options={branchItems} placeholder='Филиалы'/>*/}
                    <FilterSelect name={'group'} options={groupItems} placeholder="Группы"/>
                    <FilterSelect name={'teacher'} options={teachersOptions} placeholder='Учитель' />
                    <SearchInput name='age' type="number" placeholder="Введите возраст"/>

                    <div style={{gridColumnEnd:'-1', display:'flex', justifyContent:'flex-end'}} >
                        <Button
                            text="Отправить сообщение"
                            onClick={showCreateModal}
                            className={cn('is-outlined is-info', css(styles.createButton))}
                            icon="ion-md-add" />
                    </div>
                </aside>
            </main>

            <Table
                columns={{
                    checkbox: <input
                                type='checkbox'
                                onChange={e => {
                                    const checked = e.target.checked
                                    if(checked){
                                        setCheckedItems(customersList)
                                    } else {
                                        setCheckedItems([])
                                    }
                                }}
                                checked={checkedItems.length === customersList.length}
                              />,
                    index: '',
                    student: 'Имя',
                    phone: 'Телефон',
                    actions: '',
                }}
                items={customersList}
                renderItem={(item, index) => (
                    <StudentMessageItem
                        index={index}
                        onDelete={customers.request}
                        onUpdate={customers.request}
                        key={item.id}
                        item={item}
                        checkedItems={checkedItems}
                        setCheckedItems={setCheckedItems}
                        />
                )}
            />

            <Loader show={customers.loading} center large />

            <div ref={customers.ref} className="has-text-grey-light is-italic has-text-centered">
                {!customers.hasMore && !customers.loading && customers.length !== 0 ? 'Загрузили всех студентов' : ''}
            </div>

            <br />
        </Layout>
    )
}

const styles = StyleSheet.create({
    heading: {
        marginBottom: 0,
    },
    searchBar: {
        '@media (min-width: 900px)': {
            height: '0',
        },
    },
    search: {
        '@media (min-width: 900px)': {
            marginTop: '-4rem',
        },
    },
})
