import isEmpty from 'lodash/isEmpty'
import React, { useMemo, useState } from 'react'
import { keyBy } from 'lodash'
import CustomerBoard from '../components/CustomerBoard'
import Layout from '../components/Layout'
import { useQueryParams } from '../hooks/queryString'
import { usePersistState } from '../hooks/state'
import CustomerTable from '../components/CustomerTable'
import { useInfiniteScroll2, useLoad } from '../hooks/request'
import { CUSTOMER_DEBTORS, STATUS_LIST } from '../urls'
import { PermissionDenied } from '../components/PermissionDenied'
import { checkPermission as cp } from '../utils/auth'
import AcademyHeader from '../components/AcademyHeader'
import CustomersActionsDebtorsBar from '../components/CustomersActionsDebtorsBar'


export default function Debtors() {
    const [searchParams, setSearchParams] = useState({})
    const [view, setView] = usePersistState('customersView', 'board') // or table
    const queryParams = useQueryParams()
    const params = { type: 'active', ...queryParams, ...searchParams }
    const customers = useInfiniteScroll2({ url: CUSTOMER_DEBTORS, params }, [searchParams])

    const status = useLoad({ url: STATUS_LIST })
    const statusList = status.response && status.response.results ? status.response.results : []
    // convert `[{ id: 1 }]` to {1: { id: 1 }}
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const statsObj = useMemo(() => keyBy(statusList, 'id'), [statusList.length])

    async function onUpdate(customerId, data) {
        const results = isEmpty(data) ? (
            await customers.response.results.filter((item) => item.id !== customerId)
        ) : (
            await customers.response.results.map((item) => (
                item.id === customerId ? { ...item, ...data } : item
            ))
        )
        customers.setResponse({ count: customers.response.count, results })
    }

    if (!cp('academy.view_customer')) {
        return <PermissionDenied />
    }
    return (
        <Layout className={view !== 'table' ? 'has-background-light' : null}>
            <AcademyHeader />

            <CustomersActionsDebtorsBar
                customers={customers}
                view={view}
                onViewChange={setView}
                statusList={statusList}
                searchParams={searchParams}
                setSearchParams={setSearchParams}
            />

            {view === 'table' ? (
                <CustomerTable
                    type={params.type}
                    statsObj={statsObj}
                    onUpdate={onUpdate}
                    statuses={statusList}
                    customers={customers} />
            ) : (
                <CustomerBoard
                    type={params.type}
                    onUpdate={onUpdate}
                    columns={statusList}
                    customers={customers}
                    onReloadStatues={status.request} />
            )}
        </Layout>
    )
}
