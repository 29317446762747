/* eslint-disable no-nested-ternary */
import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import cn from 'classnames'
import { EMPLOYEE_ARCHIVE, EMPLOYEE_DETAIL } from '../urls'
import { useDeleteRequest, usePutRequest } from '../hooks/request'
import { useModal } from '../hooks/modal'
import { checkPermission as cp } from '../utils/auth'
import { useMessage } from '../hooks/message'
import Loader from './common/Loader'
import EmployeeUpdate from './EmployeeUpdate'
import EmployeePositions from './EmployeePositions'

export default function EmployeeItem({ item, onDelete, onUpdate }) {
    const employeeDelete = useDeleteRequest({ url: EMPLOYEE_DETAIL.replace('{id}', item.id) })
    const [showDropdown, setShowDropdown] = useState(false)

    const changeArchiveEmployee = usePutRequest({ url: EMPLOYEE_ARCHIVE.replace('{id}', item.id) })

    async function archiveGroup() {
        await changeArchiveEmployee.request({ data: { isArchive: !item.isArchive } })
        onUpdate()
    }

    const [showUpdateModal, hideUpdateModal] = useModal(
        <EmployeeUpdate employee={item} onUpdate={() => {
            onUpdate()
            hideUpdateModal()
        }} onCancel={() => {
            hideUpdateModal()
        }} />,
    )

    const [showMessage] = useMessage()

    async function deleteGroup() {
        if (global.confirm('Вы действительно хотите удалить?')) {
            const { error } = await employeeDelete.request()
            if (error) {
                showMessage(error.data.detail, 'is-danger')
                return
            }
            onDelete()
        }
    }

    return (
        <tr>
            <td>
                <NavLink to={`/staff/employee/${item.id}`} className="has-text-dark">
                    {item.name} {item.isArchive ? <span className="tag is-warning is-light">Архивирован</span>
                        : <span className="tag is-link is-light">Активный</span>}
                </NavLink>
            </td>
            <td>

                {item.user.email} {item.password}

            </td>
            <td>

                {item.phoneNumber}

            </td>

            <td>
                {item.birthday
                    ? new Date(item.birthday).toLocaleDateString()
                    : <span className="is-italic has-text-grey">не указан</span>}
            </td>

            <td>
                <EmployeePositions positions={item.positions} />
            </td>

            <td className="has-text-right">
                {cp('staff.delete_employee') ? (
                    !employeeDelete.loading ? (
                        <i onClick={() => deleteGroup()} className="icon pointer ion-md-trash" />
                    ) : <Loader className="icon" />
                ) : null}

                {cp('staff.change_employee') ? (
                    <i onClick={showUpdateModal} className="icon pointer ion-md-create" />
                ) : null}

                <div
                    className={cn('dropdown is-right', { 'is-active': showDropdown })}
                    onMouseLeave={() => setShowDropdown(false)}>
                    <div className="dropdown-trigger pointer">
                        <i className="icon ion-md-more" onClick={() => setShowDropdown(!showDropdown)} />
                    </div>

                    <div className="dropdown-menu" id="dropdown-menu">
                        <div className="dropdown-content">
                            {!item.isArchive ? (
                                <a className="dropdown-item" onClick={archiveGroup}>
                                    <i className="icon ion-md-checkmark" />
                                    Отправить в архив
                                </a>
                            ) : (
                                <a className="dropdown-item" onClick={archiveGroup}>
                                    <i className="icon ion-md-checkmark" />
                                    Вытащит из архива
                                </a>
                            )}
                        </div>
                    </div>
                </div>
            </td>


        </tr>
    )
}
