/* eslint-disable no-nested-ternary */
import React from 'react';
import { useDeleteRequest, usePutRequest } from '../hooks/request';
import { BRANCH_DETAIL } from '../urls';
import { checkPermission as cp } from '../utils/auth';
import { useModal } from '../hooks/modal';
import Loader from './common/Loader';
import BranchUpdate from './BranchUpdate';
import { PermissionDeniedModal } from './PermissionDeniedModal';

export default function BranchItem({ item, onDelete, onUpdate }) {
	const deleteTask = useDeleteRequest({ url: BRANCH_DETAIL.replace('{id}', item.id) });
	const changeStatusTask = usePutRequest({ url: BRANCH_DETAIL.replace('{id}', item.id) });
	const isSuperAdmin = JSON.parse(localStorage.getItem('user')).isSuperuser;
	const [showPermissionDenied, hidePermissionDenied] = useModal(<PermissionDeniedModal onSuccess={() => hidePermissionDenied()} onCancel={() => hidePermissionDenied()} />);

	async function handleDeleteTask() {
		if (global.confirm('Вы действительно хотите удалить?')) {
			await deleteTask.request();
			onDelete();
		}
	}

	async function changeStatus() {
		if (global.confirm('Вы действительно хотите изменить статус?')) {
			await changeStatusTask.request({
				data: {
					...item,
					assigned: item.assigned.id,
					status: item.status === 'waiting' ? 'complete' : 'waiting',
				},
			});
			onUpdate();
		}
	}

	const [showUpdateModal, hideUpdateModal] = useModal(
		<BranchUpdate
			task={item}
			onUpdate={() => {
				onUpdate();
				hideUpdateModal();
			}}
			onCancel={() => {
				hideUpdateModal();
			}}
		/>
	);

	return (
		<tr>
			<td>{item.name || <span className='is-italic has-text-grey'>не указан</span>}</td>
			<td>
				<span className='tag is-success is-light'>{item.customerCount} студент</span>
			</td>
			<td>
				<span className='tag is-link is-light'>{item.employeeCount} учителя</span>
			</td>
			<td>
				<div className='is-pulled-right'>
					{cp('task.delete_task') ? !deleteTask.loading ? <i onClick={isSuperAdmin ? handleDeleteTask : showPermissionDenied} className='icon ion-md-trash pointer' /> : <Loader className='icon' /> : null}

					{cp('task.change_task') ? <i onClick={showUpdateModal} className='icon ion-md-create pointer' /> : null}
				</div>
			</td>
		</tr>
	);
}
