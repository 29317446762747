import React, { Fragment, useState } from 'react'
import { NavLink, useParams } from 'react-router-dom'
import cn from 'classnames'
import moment from 'moment'
import Layout from '../components/Layout'
import { EMPLOYEE_DETAIL, EMPLOYEE_SALARY, NOTE_ITEM_LIST, SALARY_LIST } from '../urls'
import { useLoad, usePostRequest, usePutRequest } from '../hooks/request'
import Table from '../components/common/Table'
import { PermissionDenied } from '../components/PermissionDenied'
import { checkPermission as cp } from '../utils/auth'
import Button from '../components/common/Button'
import { useModal } from '../hooks/modal'
import EmployeeSalaryItem from '../components/EmployeeSalaryItem'
import { format } from '../utils/number'
import EmployeePositions from '../components/EmployeePositions'
import Loader from '../components/common/Loader'
import EmployeeInvite from '../components/EmployeeInvite'
import DateFilter from '../components/common/DateFilter'
import { useQueryParams } from '../hooks/queryString'
import { getDateTime } from '../utils/date'
import NoteItemForm from '../components/NoteItemForm'
import defaultAvatar from '../static/avatar_default.png'
import { domain } from '../utils/request'
import { StyleSheet, css } from 'aphrodite'


export default function EmployeeDetail() {
    const params = useQueryParams()
    const { employeeId } = useParams()
    const employeeSalary = useLoad({ url: EMPLOYEE_SALARY.replace('{id}', employeeId) })
    const employeeDetail = useLoad({ url: EMPLOYEE_DETAIL.replace('{id}', employeeId) })
    const employee = employeeDetail.response
    const uploadAvatar = usePutRequest({ url: '' })
    const [page, setPage] = useState(1)

    const noteItem = useLoad({ url: NOTE_ITEM_LIST, params })
    const noteItemList = noteItem.response ? noteItem.response.results : []
    const employeeFineList = noteItemList.filter(item => item?.employee?.id == employeeId)

    const [showInviteModal, hideInviteModal] = useModal(
        <EmployeeInvite
            employee={employeeDetail.response}
            onCancel={() => hideInviteModal()}
            onSuccess={() => {
                hideInviteModal()
                employeeDetail.request()
            }} />,
    )
    const fineCreate = usePostRequest({ url: NOTE_ITEM_LIST })
    const [showCreateFineModal, hideCreateFineModal] = useModal(
        <NoteItemForm onSubmit={onSubmit} initialValues={{employee: employeeId}} loading={fineCreate.loading} onCancel={()=> {hideCreateFineModal()}}  />
    )
    async function onSubmit(data, actions) {
        await fineCreate.request({ data })
        actions.resetForm()
        noteItem.request()
        hideCreateFineModal()
    }

    const transactions = useLoad({
        url: SALARY_LIST.replace('{employee_id}', employeeId),
        params: { employee: employeeId, page },
    }, [page])

    const groups = useLoad({
        url:''
    })

    if (!cp('staff.view_employee')) {
        return <PermissionDenied />
    }

    async function uploadImage({ target }) {
        const image = target.files[0]
        const newData = new FormData()
        newData.append('employeeImage', image, image.name)
        const { success, response } = await uploadAvatar.request({ data: newData })
        if (success) employeeDetail.setResponse({ ...employee, employeeImage: response.employeeImage })
    }

    return (
        <Layout>
            <NavLink className="has-text-link button is-white" to="/staff/employee">
                <i className="icon ion-md-arrow-back is-size-4" />
            </NavLink>

            <span className="is-size-4">
                Информация о сотруднике : {employee ? employee.name : ''}
            </span>

            <hr />

            {!employeeDetail.loading && employee ? (
                <main style={{display:'flex', flexWrap:'wrap', gap:'2rem'}}>
                    <label className={cn(css(styles.default))}>
                        <img
                            width={140}
                            height={140}
                            style={{objectFit:'cover'}}
                            alt=""
                            src={employee?.employeeImage ? domain + employee.employeeImage : defaultAvatar} />

                        <input className={css(styles.input)}
                            type="file"
                            name="studentImage"
                            onChange={uploadImage} />
                    </label>

                    <aside style={{flex:'auto'}}>
                        {cp('staff.add_employeeinvitation') && !employee.user && !employee.invited ? (
                            <article className="message is-warning">
                                <div className="message-body">
                                    <b>Этот сотрудник не зарегистрирован в системе!</b><br />
                                        Для приглашения нажмите на кнопку ниже и введите адрес электронной почты сотрудника.
                                        Мы отправим ему/ей электронное письмо со ссылкой на частную страницу быстрой регистрации.<br />
                                    <br />

                                    <Button
                                        text="Пригласить"
                                        icon="ion-md-person-add"
                                        onClick={showInviteModal}
                                        className="is-primary is-small" />
                                </div>
                            </article>
                        ) : null}


                        <div className="columns">
                            <b className="column">Ф.И.О: </b>
                            <span className="column">{employee.name}</span>

                            <b className="column">Доля учителя: </b>
                            <span className="column">{employee.academyShare}%</span>
                        </div>

                        <div className="columns">
                            <b className="column">Дата рождения: </b>
                            <span className="column">{employee.birthday}</span>

                            <b className="column">Доля от продаж: </b>
                            <span className="column">{employee.sellerShare}%</span>
                        </div>

                        <div className="columns">
                            <b className="column">Должность: </b>
                            <span className="column">
                                <EmployeePositions positions={employee.positions} />
                            </span>

                            <b className="column">Фиксированная зарплата: </b>
                            <span className="column">{employee.salary} Сум</span>
                        </div>

                        <div className="columns">
                            <b className="column is-3">Системный пользователь: </b>
                            <div className="column">
                                {employee.user ? employee.user.email : (
                                    <Fragment>
                                        <i className="has-text-grey">
                                            {employee.invited ? 'Был приглашен' : 'не привязан'}
                                        </i> &nbsp;

                                        {cp('staff.add_employeeinvitation') ? (
                                            <Button
                                                text={employee.invited ? 'Повторно пригласить' : 'Пригласить'}
                                                icon="ion-md-person-add"
                                                onClick={showInviteModal}
                                                className={cn('is-small', employee.invited ? 'is-warning' : 'is-link')} />
                                        ) : null}
                                    </Fragment>
                                )}
                            </div>

                            <b className="column">Номер телефона: </b>
                            <span className="column">{employee?.phoneNumber}</span>
                        </div>
                    </aside>
        </main>
            ) : <Loader large center />}

            <hr />

            <span className="is-size-4">История зарплат</span>

            <Table
                loading={employeeSalary.loading}
                totalCount={employeeSalary.response ? employeeSalary.response.length : 0}
                pageSize={employeeSalary.response ? employeeSalary.response.length : 0}
                items={employeeSalary.response ? employeeSalary.response : []}
                columns={{ name: 'Месяц', salary: 'Зарплата', actions: '' }}
                renderItem={(item) => (
                    <EmployeeSalaryItem key={item.month} item={item} employee={employee} />
                )} />

            <br />

            <span className="is-size-4">
                История выплат
            </span>

            {cp('finance.view_salary') ? (
                <Table
                    loading={transactions.loading}
                    totalCount={transactions.response ? transactions.response.count : 0}
                    items={transactions.response ? transactions.response.results : []}
                    columns={{ id: '№', amount: 'Сумма', wallet: 'Кошелек', createdAt: 'Дата создания' }}
                    activePage={page}
                    onPageChange={setPage}
                    renderItem={(transaction) => (
                        <tr key={transaction.id}>
                            <td>{transaction.id}</td>

                            <td className={transaction.amount > 0 ? 'has-text-success' : 'has-text-danger'}>
                                {format(transaction.amount)}
                            </td>

                            <td>{transaction.wallet.name}</td>
                            <td>{moment(transaction.createdAt).calendar()}</td>
                        </tr>
                    )} />
            ) : null}

            <br />

            <span className="is-size-4">
                Группы
            </span>

            <Table
                loading={groups.loading}
                totalCount={groups.response ? groups.response.length : 0}
                pageSize={groups.response ? groups.response.length : 0}
                items={groups.response ? groups.response : []}
                columns={{ groupName: 'Группы', studentAmount: 'Число учеников' }}
                renderItem={(group) => (
                    <tr key={group?.name}>
                        <td>{group?.name}</td>

                        <td >
                            {group?.studentAmount}
                        </td>
                    </tr>
            )} />

            <br />

            <header style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        columnGap: '2rem',
                        gap:'1rem',
                        flexWrap:'wrap',
            }}>
                <span className="is-size-4">
                    Штрафы
                </span>

                <hgroup style={{ display: 'flex', gap: '1rem', alignItems: 'center', flex:'auto', justifyContent:'flex-end', flexWrap:'wrap' }}>
                    <DateFilter name="startDate" />
                    <DateFilter name="endDate" />
                    <Button
                        onClick={showCreateFineModal}
                        text="Добавить"
                        icon="ion-md-add"
                        className="is-link is-outlined"/>
                </hgroup>
            </header>

            <Table
                loading={noteItem.loading}
                totalCount={employeeFineList.length}
                pageSize={employeeFineList.length}
                items={employeeFineList}
                columns={{
                    title: 'Причина',
                    mark: 'Отметка',
                    summa: 'Штрафная сумма',
                    comment: 'Комментарий',
                    createdAt: 'Создан в',
                }}
                renderItem={(item) => (
                    <tr key={item?.id}>
                        <td>{item?.note?.title}</td>
                        <td>{item?.note?.mark}</td>
                        <td>
                            {item?.note?.price}
                        </td>
                        <td>{item?.comment}</td>
                        <td>
                            {getDateTime(item.createdAt)}
                        </td>
                    </tr>
            )} />


        </Layout>
    )
}

const styles = StyleSheet.create({
    default:
        {
            display: 'flex',
            borderRadius: '5px',
            alignItems:'center',
            // width: '200px',
            // height: '190px',
            cursor: 'pointer',
        },
    input: {
        width: 'auto',
        display: 'none',
    },
})
