/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-nested-ternary */
import React from 'react';
import { checkPermission as cp } from '../utils/auth';
import { useModal } from '../hooks/modal';
import { useDeleteRequest } from '../hooks/request';
import { HOLIDAY_DETAIL } from '../urls';
import { useMessage } from '../hooks/message';
import Loader from './common/Loader';
import HolidayUpdate from './HolidayUpdate';
import { PermissionDeniedModal } from './PermissionDeniedModal';

export default function HolidayItem({ holiday, onUpdate, onDelete }) {
	const deleteRoom = useDeleteRequest();
	const [showMessage] = useMessage();
	const isSuperAdmin = JSON.parse(localStorage.getItem('user')).isSuperuser;
	const [showPermissionDenied, hidePermissionDenied] = useModal(<PermissionDeniedModal onSuccess={() => hidePermissionDenied()} onCancel={() => hidePermissionDenied()} />);

	async function deleteColumn() {
		if (global.confirm('Вы действительно хотите удалить?')) {
			const { error } = await deleteRoom.request({ url: HOLIDAY_DETAIL.replace('{id}', holiday.id) });

			if (error) {
				showMessage(error.data.detail, 'is-danger');
				return;
			}
			onDelete();
		}
	}

	const [showUpdateModal, hideUpdateModal] = useModal(
		<HolidayUpdate
			holiday={holiday}
			onSuccess={async () => {
				await onUpdate();
				hideUpdateModal();
			}}
			onCancel={() => {
				hideUpdateModal();
			}}
		/>
	);

	return (
		<tr>
			<td>{holiday.title}</td>
			<td>{holiday.date}</td>

			<td>
				<div className='is-pulled-right'>
					{cp('academy.delete_room') ? !deleteRoom.loading ? <i onClick={isSuperAdmin ? deleteColumn : showPermissionDenied} className='icon pointer ion-md-trash' /> : <Loader className='icon' /> : null}

					{cp('academy.change_room') ? <i onClick={showUpdateModal} className='icon pointer ion-md-create' /> : null}
				</div>
			</td>
		</tr>
	);
}
