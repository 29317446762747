import React from 'react';
import Layout from '../components/Layout';
import { useInfiniteScroll } from '../hooks/request';
import { GROUPS_LIST } from '../urls';
import Table from '../components/common/Table';
import { useQueryParams } from '../hooks/queryString';
import GroupItem from '../components/GroupItem';
import Button from '../components/common/Button';
import GroupCreate from '../components/GroupCreate';
import { useModal } from '../hooks/modal';
import { checkForAdmin, checkPermission as cp } from '../utils/auth';
import { PermissionDenied } from '../components/PermissionDenied';
// import FilterByBranch from '../components/common/FilterByBranch';
import AcademyHeader from '../components/AcademyHeader';

export default function Group() {
	const isAdmin = checkForAdmin();
	const params = useQueryParams();
	const groups = useInfiniteScroll({ url: GROUPS_LIST, params: { ...params } });
	const groupsList = groups.response?.results || [];
	const filteredGroups = groupsList.filter(item => !item.isArchive);

	const [showCreateModal, hideCreateModal] = useModal(
		<GroupCreate
			onSuccess={() => {
				hideCreateModal();
				groups.reload();
			}}
			onCancel={() => hideCreateModal()}
		/>
	);

	if (!cp('academy.view_group')) {
		return <PermissionDenied />;
	}

	return (
		<Layout>
			<AcademyHeader />
			{cp('academy.add_customer') ? (
				<div style={{ gap: 10, display: 'flex', alignItems: 'center', justifyContent: 'flex-end', flexWrap: 'wrap' }}>
					{/*<FilterByBranch />*/}
					<Button style={{ marginLeft: 10 }} onClick={showCreateModal} text='Создать Группу' icon='ion-md-add' className='is-pulled-right is-link is-outlined is-size-6 ml-5' />
				</div>
			) : null}

			<br />

			<Table
				style={{ paddingBottom: 50 }}
				loading={groups.loading}
				showEmptyMessage={false}
				columns={{ index: '', group: 'Название', timelines: 'Расписание занятий', students: 'Студенты', teacher: 'Учитель', subject: 'Предмет', room: 'Кабинет', actions: '' }}
				items={isAdmin ? groupsList : filteredGroups}
				renderItem={(item, index) => <GroupItem index={index} key={item.id} onUpdate={groups.reload} onDelete={groups.reload} item={item} />}
				children={
					<div ref={groups.ref} className='has-text-grey-light is-italic has-text-centered'>
						{!groups.hasMore && !groups.loading && groups.length !== 0 ? 'Загрузили всех групп' : ''}
					</div>
				}
			/>
		</Layout>
	);
}
