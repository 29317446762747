/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import cn from 'classnames'
import { stringify } from 'query-string'
import { useHistory } from 'react-router-dom'
import { css, StyleSheet } from 'aphrodite'
import { useQueryParams } from '../hooks/queryString'
import { useModal } from '../hooks/modal'
import CustomerCreate from './CustomerCreate'
import FilterSelect from './common/FilterSelect'
import { useLoad } from '../hooks/request'
import { BRANCH_LIST, EMPLOYEE_LIST, GROUPS_LIST, SUBJECT_LIST } from '../urls'

export default function CustomersActionsDebtorsBar({
                                                       view,
                                                       onViewChange,
                                                       customers,
                                                       statusList,
                                                   }) {
    const history = useHistory()
    const params = useQueryParams()

    function filterByType(type) {
        history.push(`?${stringify({ ...params, type })}`)
    }

    const [showCreateModal, hideCreateModal] = useModal(
        <CustomerCreate
            onCancel={() => hideCreateModal()}
            onSuccess={() => {
                customers.reload()
                hideCreateModal()
            }}/>
    )

    const branch = useLoad({ url: BRANCH_LIST })
    const branchItems = branch.response ? branch.response : []

    const group = useLoad({ url: GROUPS_LIST })
    const groupItems = group.response?.results || []

    const teachers = useLoad({ url: EMPLOYEE_LIST, params: { positions: 'teacher', size: 1000, isArchive: 'archive' } })
    const teachersOptions = teachers.response?.results || []

    const subject = useLoad({ url: SUBJECT_LIST })
    const subjectList = subject.response?.results || []
    const monthOptions = [
        { name: 'Январь', id: 'january' },
        { name: 'Февраль', id: 'february' },
        { name: 'Март', id: 'march' },
        { name: 'Апрель', id: 'april', },
        { name: 'Май', id: 'may' },
        { name: 'Июнь', id: 'june' },
        { name: 'Июль', id: 'july' },
        { name: 'Август', id: 'august' },
        { name: 'Сентябрь', id: 'september' },
        { name: 'Октябрь', id: 'october' },
        { name: 'Ноябрь', id: 'november' },
        { name: 'Декабрь', id: 'december' },
    ];

    return (
        <main className={cn(css(styles.parent))}>
            <aside
                style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', gap: '1rem', marginBottom: '1rem' }}>
                <hgroup>
                    <div className="field has-addons are-small">
                        <p className="control">
                            <button className="button is-small"
                                    onClick={() => onViewChange('table')}
                                    disabled={view === 'table'}
                                    title="Отобразить в виде таблицы">

                                <i className={cn('icon ion-md-grid', css(styles.icon))}/>
                            </button>
                        </p>

                        <p className="control">
                            <button className="button is-small"
                                    onClick={() => onViewChange('board')}
                                    disabled={view === 'board'}
                                    title="Отобразить в виде колонок">
                                <i className={cn('icon ion-md-list', css(styles.icon))}/>
                            </button>
                        </p>
                    </div>
                </hgroup>

                {/* <div>
                <div className="field has-addons are-small">
                    <p className="control">
                        <button className="button is-small"
                            onClick={() => onViewChange('board')}
                            disabled={view === 'board'}
                            title="Отобразить в виде колонок">
                            <i className="icon ion-md-mail" />
                            <p>Сообщение</p>
                        </button>
                    </p>
                </div>
            </div> */}


                <hgroup>
                    <div className="tags is-right">
                        <a onClick={() => filterByType('')}
                           className={cn('tag', { 'light-info': params.type === '' })}>

                            <i className="icon ion-ios-people"/>
                            Все клиенты
                        </a>

                        <a onClick={() => filterByType('active')}
                           className={cn('tag', { 'light-primary': params.type === undefined || params.type === 'active' })}>

                            <i className="icon ion-ios-flame"/>
                            Активные
                        </a>

                        <a onClick={() => filterByType('rejected')}
                           className={cn('tag', { 'light-danger': params.type === 'rejected' })}>

                            <i className="icon ion-ios-warning"/>
                            Отказавшиеся
                        </a>

                        <a onClick={() => filterByType('archived')}
                           className={cn('tag', { 'light-gray': params.type === 'archived' })}>

                            <i className="icon ion-ios-filing"/>
                            Архив
                        </a>
                    </div>
                </hgroup>

                <div style={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(auto-fill, minmax(12rem, auto))',
                    gap: '1rem',
                    width: '100%',
                    marginBottom: '2rem'
                }}
                >
                    <FilterSelect name={'subject'} options={subjectList} placeholder='Предмет'/>
                    {view !== 'board' && (
                        <FilterSelect name={'state'} optionLabelKey='title' options={statusList}
                                      placeholder='Состояние'/>)}
                    <FilterSelect name={'teacher'} options={teachersOptions} placeholder='Учитель'/>
                    <FilterSelect name={'group'} options={groupItems} placeholder="Группы"/>
                    <FilterSelect name={'month'} options={monthOptions} placeholder="Месяц"/>
                </div>


            </aside>


        </main>
    )
}

const styles = StyleSheet.create({
    parent: {},
    icon: {
        transform: 'rotate(90deg) scale(1.5)',
    },
    modal: {
        width: 1000
    }
})

