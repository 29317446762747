/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react'
import cn from 'classnames'
import { StyleSheet } from 'aphrodite'
import moment from 'moment'
import TaskUpdate from './TaskUpdate'
import { useDeleteRequest, useLoad, usePutRequest } from '../hooks/request'
import { BOARD_TASK_MOVE, COMMENT_LIST, TASK_DETAIL } from '../urls'
import { useModal } from '../hooks/modal'
import TaskDetails from './TaskDetails'
import { getDateTime } from '../utils/date'

export default function TaskItem({ item, onDelete, onUpdate, board, onPointsUpdate, tasks }) {
    const deleteTask = useDeleteRequest({ url: TASK_DETAIL.replace('{id}', item.id) })
    const changeStatusTask = usePutRequest({ url: TASK_DETAIL.replace('{id}', item.id) })
    const comment = useLoad({ url: COMMENT_LIST, params: { task: item.task.id } })
    const commentItem = comment.response ? comment.response.results : []

    async function handleDeleteTask() {
        if (global.confirm('Haqiqatan ham oʻchirib tashlamoqchimisiz?')) {
            await deleteTask.request()
            onDelete()
        }
    }

    async function changeStatus() {
        if (global.confirm('Haqiqatan ham holatni o‘zgartirmoqchimisiz?')) {
            await changeStatusTask.request({
                data: {
                    ...item,
                    assigned: item.assigned.id,
                    status: item.status === 'waiting' ? 'complete' : 'waiting',
                },
            })
            onUpdate()
        }
    }

    const moveBoard = usePutRequest()

    async function move(id, position, status) {
        const url = BOARD_TASK_MOVE.replace('{board_id}', board.id).replace('{id}', id)
        await moveBoard.request({ url, data: { position, status } })
    }

    async function onCompleted() {
        await move(item.id, 1, 'done')
        await tasks.request()
    }

    async function onCompletedDoing() {
        await move(item.id, 1, 'doing')
        await tasks.request()
    }

    const [showUpdateModal, hideUpdateModal] = useModal(
        <TaskUpdate task={item} onUpdate={() => {
            onUpdate()
            hideUpdateModal()
        }} onCancel={() => {
            hideUpdateModal()
        }} />,
    )

    let statusClass

    if (item.status === 'todo') {
        statusClass = 'has-text-danger'
    } else if (item.status === 'doing') {
        statusClass = 'has-text-link'
    } else if (item.status === 'done') {
        statusClass = 'has-text-black'
    } else {
        statusClass = 'has-text-success'
    }
    let status

    if (item.status === 'todo') {
        status = 'Yangi topshiriq'
    } else if (item.status === 'doing') {
        status = 'Topshiriq bajarilmoqda'
    } else if (item.status === 'done') {
        status = 'Topshiriq bajarildi'
    } else {
        status = 'Topshiriq bajarildi'
    }


    let statusTag

    if (item.status === 'todo') {
        statusTag = 'is-danger'
    } else if (item.status === 'doing') {
        statusTag = 'is-link'
    } else if (item.status === 'done') {
        statusTag = 'is-success'
    } else {
        statusTag = 'is-success'
    }

    const [showDetailsModal, hideDetailsModal] = useModal(
        <TaskDetails
            task={item.task}
            boardTask={item}
            board={board}
            onUpdate={onUpdate}
            onPointsUpdate={onPointsUpdate}
            onCompleted={() => {
                onCompleted(item.task.id)

                hideDetailsModal()
            }}
            onCompletedDoing={() => {
                onCompletedDoing(item.task.id)

                hideDetailsModal()
            }}
            onDelete={(id) => {
                onDelete(id)
                hideDetailsModal()
            }} />,
        styles.modal,
    )

    const [durationString, setDurationString] = useState(item.duration)
    const [formattedDuration, setFormattedDuration] = useState('')
    useEffect(() => {
    // Parse the duration string using moment
        const durationMoment = moment.duration(durationString)

        // Extract days, hours, minutes, and seconds
        const days = durationMoment.days()
        const hours = durationMoment.hours()
        const minutes = durationMoment.minutes()

        // Create the formatted duration string
        let formatted = ''
        if (days > 0) {
            formatted += `${days} kun `
        }
        if (hours > 0) {
            formatted += `${hours} soat `
        }
        formatted += `${minutes} minut`

        // Update the state with the formatted duration
        setFormattedDuration(formatted.trim())
    }, [durationString])


    return (
        <tr className={statusClass}>
            <td><span className={cn('is-light tag', statusTag)}>#{item.task.id}</span></td>
            <td>
                <span className={cn('is-light tag', statusTag)}>{!item.task.createdAt ? (
                    <span className="is-italic has-text-grey">ko'rsatilmagan</span>
                ) : getDateTime(item.task.createdAt)}
                </span>
            </td>
            <td onClick={showDetailsModal} style={{ width: 300 }}>
                {item.task.title
                    || <span className="is-italic has-text-grey ">ko'rsatilmagan</span>}
            </td>
            <td>{item.task.assigned.name || item.task.assigned.lastName || item.task.assigned.email}</td>
            <td>
                {!item.duration ? (
                    <span className="is-italic has-text-grey">ko'rsatilmagan</span>
                ) : formattedDuration} <br />
            </td>

            <td>
                <span className={cn('pointer is-rounded tag is-light', statusTag)}>{status}</span>
            </td>
            <td style={{ width: 200 }}>
                {commentItem.map((element) => (

                    <span
                        className="is-bold has-text-black tag is-warning is-light m-1">{element.title ? element.title : 'No comment'}
                    </span>

                ))}
            </td>

        </tr>
    )
}


const styles = StyleSheet.create({
    card: {
        userSelect: 'none',
        marginBottom: '0.5rem',
        borderRadius: '3px',
    },
    title: {
        overflowWrap: 'break-word',
        wordWrap: 'break-word',
        hyphens: 'auto',
        '@media (max-width: 769px)': {
            maxWidth: '30rem',
        },
    },
    active: {
        // drugging styles goes here
    },
    modal: {
        width: 800,
    },
})
