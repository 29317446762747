import React, { useEffect, useState } from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import { NavLink, useRouteMatch } from 'react-router-dom'

import { checkPermission as cp, signinModal } from '../utils/auth'
import { getEnv } from '../utils/environment'
import { useLoad, usePostRequest } from '../hooks/request'
import { GENERAL, SIGNIN } from '../urls'
import AccountPanel from './AccountPanel'
import logo from '../static/logoo.png'

export default function Layout({ children, padding = true, className }) {
    const { path } = useRouteMatch()
    const [menu, setMenu] = useState(false)
    const [password, setPassword] = useState('')
    const [loading, setLoading] = useState(false)
    const general = useLoad({ url: GENERAL })
    const [error, setError] = useState('')

    const assign = JSON.parse(localStorage.getItem('user'))
    const [modalOpen, setModalOpen] = useState(false)
    const signIn = usePostRequest({ url: SIGNIN })

    useEffect(() => {
        const isModalOpen = localStorage.getItem('isModalOpen') === 'true'
        setModalOpen(isModalOpen)
    }, [])

    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)
        setError('')
        const user = JSON.parse(localStorage.getItem('user'))
        const data = { email: user.email, password }

        try {
            const { response, success } = await signIn.request({ data })

            if (success) {
                setModalOpen(false)
                localStorage.setItem('isModalOpen', 'false')
                signinModal(response)
                setPassword('')
            } else {
                throw new Error('Authentication failed')
            }
        } catch (err) {
            setError(err.message)
        } finally {
            setLoading(false)
        }
    }

    const toggleFullscreen = () => {
        if (!document.fullscreenElement) {
            document.documentElement.requestFullscreen()
        } else if (document.exitFullscreen) {
            document.exitFullscreen()
        }
    }

    const handleIconClick = () => {
        setModalOpen(true)
        localStorage.setItem('isModalOpen', 'true')
    }
    const isSuperAdmin = JSON.parse(localStorage.getItem('user')).isSuperuser

    return (
        <div>
            <div className={css(styles.sidebar, menu ? styles.desktopSidebar : styles.mobileSidebar, getEnv() === 'development' ? styles.developmentSidebar : null, getEnv() === 'staging' ? styles.stagingSidebar : null)}>
                <div style={{ display: 'flex', gap: 2, justifyContent: 'flex-end', alignItems: 'start', padding: 10 }}>
                    <i className={cn('icon is-size-4 ion-md-expand', css(styles.icon))} onClick={toggleFullscreen} />
                    <i className={cn('icon is-size-4 ion-md-lock', css(styles.icon))} onClick={handleIconClick} />
                </div>
                <img style={{ paddingRight: 10, visibility: 'hidden' }} src={logo} className={css(styles.logo)} alt="Craftsoft" />

                <AccountPanel />

                {/* <i onClick={() => setMenu(false)} className={cn(
                    'icon ion-md-arrow-back is-size-4',
                    css(styles.hiderIcon),
                )} /> */}

                <p className={css(styles.menuHeading)}>Меню</p>

                <ul className={css(styles.menu)}>
                    {cp('academy.view_group') || cp('academy.view_advert') || cp('academy.view_customers') ? (
                        <li onClick={() => setMenu(false)}>
                            <NavLink to="/academy/group"
                                className={cn(css(styles.link), { [css(styles.active)]: path.startsWith('/academy') })}>
                                <i className={cn('icon ion-md-school', css(styles.icon))} />
                                Академия
                            </NavLink>
                        </li>
                    ) : null}

                    {cp('finance.view_wallet') || cp('finance.view_transactioncategory') ? (
                        <li onClick={() => setMenu(false)}>
                            <NavLink className={css(styles.link, path.startsWith('/finance') ? styles.active : null)}
                                to="/finance/wallet">
                                <i className={cn('icon ion-md-wallet', css(styles.icon))} />
                                Финансы
                            </NavLink>
                        </li>
                    ) : null}

                    {cp('staff.view_employee') ? (
                        <li onClick={() => setMenu(false)}>
                            <NavLink to="/staff/employee"
                                className={css(styles.link, path.startsWith('/staff/employee') ? styles.active : null)}>
                                <i className={cn('icon ion-md-contacts', css(styles.icon))} />
                                Персонал
                            </NavLink>
                        </li>
                    ) : null}

                    {isSuperAdmin ? (
                        <li onClick={() => setMenu(false)}>
                            <NavLink to="/branch"
                                className={css(styles.link, path.startsWith('/branch') ? styles.active : null)}>
                                <i className={cn('icon ion-md-git-branch', css(styles.icon))} />
                                Филиалы &nbsp;
                                <span
                                    className="tag is-success is-rounded">{general.response ? general.response.tasksCount : 0}
                                </span>
                            </NavLink>
                        </li>
                    ) : null}

                    {cp('finance.delete_wallet') ? (
                        <li onClick={() => setMenu(false)}>
                            <NavLink to="/statistics/academy"
                                className={css(styles.link, path.startsWith('/statistics') ? styles.active : null)}>
                                <i className={cn('icon ion-md-stats', css(styles.icon))} />
                                Статистика
                            </NavLink>
                        </li>
                    ) : null}


                    <li onClick={() => setMenu(false)}>
                        <NavLink to="/task/board/1" className={css(
                            styles.link,
                            path.startsWith('/task') ? styles.active : null,
                        )}>
                            <i className={cn('icon ion-md-checkbox', css(styles.icon))} />
                            Задачи &nbsp;

                            {/* <span className="tag is-success is-rounded">
                                {general.response ? general.response.tasksCount : 0}
                            </span> */}
                        </NavLink>
                    </li>

                    <li onClick={() => setMenu(false)}>
                        <NavLink to="/settings/user"
                            className={cn(css(styles.link), { [css(styles.active)]: path.startsWith('/settings/user') })}>
                            <i className={cn('icon ion-md-settings', css(styles.icon))} />
                            Настройки
                        </NavLink>
                    </li>
                </ul>
            </div>

            <div className={cn(css(styles.container))}>
                <div className={cn(className, css(styles.content), { [css(styles.padding)]: padding })}>
                    <div>
                        <i onClick={() => setMenu(true)}
                            className={cn('icon ion-md-menu is-size-3', css(styles.showerIcon))} />
                    </div>
                    {children}
                </div>
            </div>

            <div className={css(menu ? styles.background : null)} onClick={() => setMenu(false)} />
            {modalOpen && (
                <div className={css(styles.modal)}>
                    <div className={css(styles.modalContent)}>
                        <form onSubmit={handleSubmit}>
                            <h2>Введите пароль</h2>
                            <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} className={css(styles.input)} placeholder="пароль" required />
                            {error && <p className={css(styles.error)}>{error}</p>}
                            <button type="submit" className={css(styles.submitButton)} disabled={loading}>
                                {loading ? 'Загрузка...' : 'Войти'}
                            </button>
                        </form>
                    </div>
                </div>
            )}
        </div>
    )
}

const styles = StyleSheet.create({
    sidebar: {
        minHeight: '100%',
        position: 'fixed',
        background: '#1f2668',
        width: '14.1rem',
        margin: '0',
        color: 'white',
        padding: '0 1.2rem 0 2.8rem',
        zIndex: '5',
        '::-webkit-scrollbar': {
            display: 'none',
        },
        '@media (max-height: 525px)': {
            height: 'calc(100vh - 100%)',
            overflowX: 'scroll',
        },
    },
    developmentSidebar: {
        background: 'RGBA(31, 38, 104)',
    },
    stagingSidebar: {
        background: 'RGBA(31, 38, 104)',
    },
    desktopSidebar: {
        '@media (max-width: 769px)': {
            transform: 'translateX(0)',
            transition: 'all 300ms',
        },
    },
    mobileSidebar: {
        '@media (max-width: 769px)': {
            transform: 'translateX(-15rem)',
            transition: 'all 300ms',
        },
    },
    icon: {
        margin: '0 0.3rem 0.9rem 0',
    },
    link: {
        color: 'RGBA(255, 255, 255, .6)',
    },
    logo: {
        margin: '1.4rem 0.1rem',
        width: '8rem',
    },
    menuHeading: {
        color: 'RGBA(255,255,255, .6)',
        margin: '0 0 1.5rem 0',
    },
    menu: {
        margin: '0 0 3.1rem 0',
    },
    padding: {
        padding: '1.8rem',
    },
    container: {
        margin: '0 0 0 14rem',
        '@media (max-width: 769px)': {
            margin: '0',
        },
    },
    active: {
        fontWeight: 'bolder',
        color: 'white',
    },
    content: {
        minHeight: '100vh',
    },
    showerIcon: {
        '@media (min-width: 769px)': {
            display: 'none',
        },
    },
    hiderIcon: {
        transform: 'translate(9.6rem, -15rem)',
        color: 'white',
        '@media (min-width: 769px)': {
            display: 'none',
        },
    },
    background: {
        '@media (max-width: 769px)': {
            bottom: 0,
            left: 0,
            position: 'fixed',
            right: 0,
            top: 0,
            background: 'RGBA(0, 0, 0, .5)',
            zIndex: 4,
        },
    },
    modal: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1000,
    },
    modalContent: {
        background: 'white',
        padding: '2rem',
        borderRadius: '8px',
        width: '300px',
        position: 'relative',
    },
    closeButton: {
        position: 'absolute',
        top: '10px',
        right: '10px',
        cursor: 'pointer',
        fontSize: '18px',
        border: 'none',
        background: 'transparent',
    },
    input: {
        width: '100%',
        padding: '0.5rem',
        margin: '1rem 0',
        border: '1px solid #ccc',
        borderRadius: '4px',
    },
    submitButton: {
        width: '100%',
        padding: '0.5rem',
        backgroundColor: '#007bff',
        color: 'white',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
    },
    error: {
        color: 'red',
        margin: '0.5rem 0',
    },
})
