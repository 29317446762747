import React from 'react'
import isEmpty from 'lodash/isEmpty'
import { checkPermission as cp } from '../utils/auth'
import { useDeleteRequest } from '../hooks/request'
import { STUDENT_DETAIL } from '../urls'
import Loader from './common/Loader'
import { useMessage } from '../hooks/message'
import { getDate } from '../utils/date'
import { useModal } from '../hooks/modal'
import CustomerInfoGroupsUpdate from './CustomerInfoGroupsUpdate'
import Button from './common/Button'

export default function CustomerInfoGroupsItem({ customer, item, onDelete, createStudent, index }) {
    const [showMessage] = useMessage()
    const url = STUDENT_DETAIL.replace('{id}', item.id)
    const studentDelete = useDeleteRequest({ url })


    async function removeStudent() {
        if (global.confirm('Вы действительно хотите удалить?')) {
            const { error } = await studentDelete.request()

            if (error) {
                showMessage(error.data.detail, 'is-danger')
                return
            }
            const newCustomer = { ...customer, students: customer.students.filter((student) => student !== item) }
            // onDelete(newCustomer.id, newCustomer)
            createStudent.request()
        }
    }

    const [showGroupUpdateModal, hideGroupUpdateModal] = useModal(
        <CustomerInfoGroupsUpdate
            item={item}
            customer={customer}
            onCancel={() => hideGroupUpdateModal()}
            onSuccess={() => {
                hideGroupUpdateModal()
                createStudent.request()
            }} />,
    )


    return (
        <tr>
            <td>{index + 1}</td>
            <td>{item.group.name}</td>
            <td>{item.group.subject?.name}</td>
            <td>{item.price} Сум</td>
           {/* <td>
                {!isEmpty(customer.students) ? (
                    <>
                        {item.lessonCount <= 0
                            ? (
                                <span style={{ marginLeft: '1rem' }} className="tag is-rounded is-danger is-light">
                                    {item.lessonCount}
                                </span>
                            )
                            : (
                                <span style={{ marginLeft: '1rem' }} className="tag is-rounded is-link is-light">
                                    {item.lessonCount}
                                </span>
                            )}
                    </>
                ) : null}


            </td>*/}
            <td><span className="tag is-light is-link">{item.startedDate ? getDate(item.startedDate) : ''}</span></td>
            <td>{item.endedDate ? getDate(item.endedDate) : ''}</td>
            <td>{item.isStudent ? <span className="tag is-light is-danger">Остановлено</span>
                : <span className="tag is-light is-success">Продолжать</span>}
            </td>

            <td className="">
                <div style={{ gap: 5 }} className="is-flex is-pulled-right">
                    {cp('academy.change_student') ? (
                        <Button onClick={showGroupUpdateModal} icon="icon ion-md-create"
                            className="is-outlined is-link is-small" />
                    ) : null}

                    {cp('academy.delete_student') && !studentDelete.loading ? (
                        <Button onClick={removeStudent} icon="icon ion-md-trash"
                            className="is-outlined is-danger is-small" />
                    ) : null}

                    <Loader show={studentDelete.loading} className="icon" />
                </div>

            </td>
        </tr>
    )
}
