/* eslint-disable no-nested-ternary */
import React, { Fragment } from 'react';
import { STATUS_DETAIL } from '../urls';
import { useDeleteRequest } from '../hooks/request';
import { useMessage } from '../hooks/message';
import { checkPermission as cp } from '../utils/auth';
import { useModal } from '../hooks/modal';
import ColumnUpdate from './ColumnUpdate';
import Loader from './common/Loader';
import { PermissionDeniedModal } from './PermissionDeniedModal';

export default function ColumnItem({ status, changePosition, onDelete, columns, onUpdate }) {
	const deleteStatus = useDeleteRequest();
	const isSuperAdmin = JSON.parse(localStorage.getItem('user')).isSuperuser;
	const [showPermissionDenied, hidePermissionDenied] = useModal(<PermissionDeniedModal onSuccess={() => hidePermissionDenied()} onCancel={() => hidePermissionDenied()} />);

	const [showMessage] = useMessage();
	async function deleteColumn() {
		if (global.confirm('Вы действительно хотите удалить?')) {
			const { error } = await deleteStatus.request({ url: STATUS_DETAIL.replace('{id}', status.id) });

			if (error) {
				showMessage(error.data.detail, 'is-danger');
				return;
			}
			onDelete();
		}
	}

	const [showUpdateModal, hideUpdateModal] = useModal(
		<ColumnUpdate
			status={status}
			onSuccess={async () => {
				await onUpdate();
				hideUpdateModal();
			}}
			onCancel={() => {
				hideUpdateModal();
			}}
		/>
	);

	return (
		<tr>
			<td>
				<span style={{ color: status.color }}>{status.title}</span>

				<span className='has-text-grey-light'>{status.type !== 'intermediate' ? ' (системный)' : null}</span>
			</td>

			<td>
				<div className='is-pulled-right'>
					{status.position <= columns.length - 2 && cp('academy.change_status') && status.type === 'intermediate' ? <i onClick={() => changePosition(status, 1)} className='icon ion-md-arrow-down pointer' /> : null}

					{status.position > 2 && cp('academy.change_status') && status.type === 'intermediate' ? <i onClick={() => changePosition(status, -1)} className='icon ion-md-arrow-up pointer' /> : null}

					{status.type === 'intermediate' && cp('academy.change_status') ? (
						<Fragment>
							&nbsp; &nbsp; &nbsp;
							{cp('academy.delete_status') ? !deleteStatus.loading ? <i onClick={isSuperAdmin ? deleteColumn : showPermissionDenied} className='icon pointer ion-md-trash' /> : <Loader className='icon' /> : null}
							{cp('academy.change_status') ? <i onClick={showUpdateModal} className='icon pointer ion-md-create' /> : null}
						</Fragment>
					) : null}
				</div>
			</td>
		</tr>
	);
}
