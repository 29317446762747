/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-nested-ternary */
import cn from 'classnames';
import React, { useState } from 'react';
import { checkPermission as cp } from '../utils/auth';
import { useModal } from '../hooks/modal';
import { useDeleteRequest, usePutRequest } from '../hooks/request';
import { NOTE_DETAIL, ROOM_CHANGE_MAIN } from '../urls';
import { useMessage } from '../hooks/message';
import Loader from './common/Loader';
import NoteUpdate from './NoteUpdate';
import { PermissionDeniedModal } from './PermissionDeniedModal';

export default function NoteItem({ item, onUpdate, onDelete }) {
	const deleteRoom = useDeleteRequest();
	const [showMessage] = useMessage();
	const isSuperAdmin = JSON.parse(localStorage.getItem('user')).isSuperuser;
	const [showPermissionDenied, hidePermissionDenied] = useModal(<PermissionDeniedModal onSuccess={() => hidePermissionDenied()} onCancel={() => hidePermissionDenied()} />);

	async function deleteColumn() {
		if (global.confirm('Вы действительно хотите удалить?')) {
			const { error } = await deleteRoom.request({ url: NOTE_DETAIL.replace('{id}', item.id) });

			if (error) {
				showMessage(error.data.detail, 'is-danger');
				return;
			}
			onDelete();
		}
	}

	const [showUpdateModal, hideUpdateModal] = useModal(
		<NoteUpdate
			room={item}
			onSuccess={async () => {
				await onUpdate();
				hideUpdateModal();
			}}
			onCancel={() => {
				hideUpdateModal();
			}}
		/>
	);

	return (
		<tr>
			<td>
				<span className='tag is-light is-link is-medium is-uppercase'>{item.mark}</span> {item.title}
			</td>

			<td>
				<div className='is-pulled-right is-flex'>
					{cp('academy.delete_room') ? !deleteRoom.loading ? <i onClick={isSuperAdmin ? deleteColumn : showPermissionDenied} className='icon pointer ion-md-trash' /> : <Loader className='icon' /> : null}

					{cp('academy.change_room') ? <i onClick={showUpdateModal} className='icon pointer ion-md-create' /> : null}
				</div>
			</td>
		</tr>
	);
}
