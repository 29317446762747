import { Form, Formik } from 'formik'
import React from 'react'
import { required } from '../utils/validators'
import Button from './common/Button'
import Select from './common/Select'
import { useLoad } from '../hooks/request'
import { BRANCH_LIST } from '../urls'

export default function UserCompanyForm({ onSubmit, user, loading }) {
    const branch = useLoad({ url: BRANCH_LIST })

    return (
        <Formik initialValues={user} onSubmit={onSubmit}>
            <Form>
                <Select
                    // empty
                    name="company"
                    label="Филиал"
                    loading={branch.loading}
                    options={branch.response ? branch.response : []}
                    help="Выберите филиал"
                    validate={required} />
                <Button
                    loading={loading}
                    text="Сохранить"
                    type="submit"
                    icon="ion-md-checkmark"
                    disabled={loading}
                    className="is-success" /> &nbsp;
            </Form>
        </Formik>
    )
}
