import sortBy from 'lodash/sortBy';
import React from 'react';
import Layout from '../components/Layout';
import { useLoad, usePutRequest } from '../hooks/request';
import { HOLIDAY_LIST, NOTE_LIST, ROOM_LIST, SALES_CHANNEL_LIST, STATUS_LIST, STATUS_MOVE, SUBJECT_LIST } from '../urls';
import Table from '../components/common/Table';
import { checkPermission as cp } from '../utils/auth';
import Button from '../components/common/Button';
import { useModal } from '../hooks/modal';
import ColumnCreate from '../components/ColumnCreate';
import RoomCreate from '../components/RoomCreate';
import ColumnItem from '../components/ColumnItem';
import RoomItem from '../components/RoomItem';
import SettingsTabs from '../components/SettingsTabs';
import Card from '../components/common/Card';
import SalesChannelItem from '../components/SalesChannelItem';
import SalesChannelCreate from '../components/SalesChannelCreate';
import NoteItem from '../components/NoteItem';
import NoteCreate from '../components/NoteCreate';
import HolidayCreate from '../components/HolidayCreate';
import HolidayItem from '../components/HolidayItem';
import SubjectCreate from '../components/SubjectCreate';
import SubjectItem from '../components/SubjectItem';

export default function SettingsAcademy() {
	const statuses = useLoad({ url: STATUS_LIST });
	const rooms = useLoad({ url: ROOM_LIST });
	const notes = useLoad({ url: NOTE_LIST });
	const subjects = useLoad({ url: SUBJECT_LIST });
	const holidays = useLoad({ url: HOLIDAY_LIST });
	const statusMove = usePutRequest();
	const columns = statuses.response && statuses.response.results ? statuses.response.results : [];
	const salesChannels = useLoad({ url: SALES_CHANNEL_LIST }, []);

	const [showSalesChanelCreateModal, hideSalesChanelCreateModal] = useModal(
		<SalesChannelCreate
			onCancel={() => {
				hideSalesChanelCreateModal();
			}}
			onSuccess={() => {
				hideSalesChanelCreateModal();
				salesChannels.request();
			}}
		/>
	);

	const [showColumnCreateModal, hideColumnCreateModal] = useModal(
		<ColumnCreate
			onSuccess={() => {
				hideColumnCreateModal();
				statuses.request();
			}}
			onCancel={() => hideColumnCreateModal()}
		/>
	);

	const [showRoomCreateModal, hideRoomCreateModal] = useModal(
		<RoomCreate
			onSuccess={async () => {
				await rooms.request();
				hideRoomCreateModal();
			}}
			onCancel={() => hideRoomCreateModal()}
		/>
	);
	const [showHolidayCreateModal, hideHolidayCreateModal] = useModal(
		<HolidayCreate
			onSuccess={async () => {
				await holidays.request();
				hideHolidayCreateModal();
			}}
			onCancel={() => hideHolidayCreateModal()}
		/>
	);
	const [showNoteCreateModal, hideNoteCreateModal] = useModal(
		<NoteCreate
			onSuccess={async () => {
				await notes.request();
				hideNoteCreateModal();
			}}
			onCancel={() => hideNoteCreateModal()}
		/>
	);
	const [showSubjectCreateModal, hideSubjectCreateModal] = useModal(
		<SubjectCreate
			onSuccess={async () => {
				await subjects.request();
				hideSubjectCreateModal();
			}}
			onCancel={() => hideSubjectCreateModal()}
		/>
	);

	function changePosition(status, direction) {
		statuses.setResponse({
			results: columns.map(column => {
				if (column.id === status.id) {
					return { ...column, position: status.position + direction };
				}
				if (column.position === status.position + direction) {
					return { ...column, position: status.position };
				}
				return column;
			}),
		});
		statusMove.request({
			url: STATUS_MOVE.replace('{id}', status.id),
			data: { position: status.position + direction },
		});
	}

	return (
		<Layout className='has-background-light'>
			<SettingsTabs />

			<div className='columns'>
				<div className='column'>
					<Card>
						<div className='columns'>
							<div className='is-size-4 column'>Этапы продаж (Воронка)</div>

							{cp('staff.add_employee') ? (
								<div className='column is-narrow'>
									<Button onClick={showColumnCreateModal} text='Добавить' icon='ion-md-add' className='is-pulled-right is-link is-outlined' />
								</div>
							) : null}
						</div>

						<Table loading={statuses.loading} items={sortBy(columns, 'position')} renderItem={status => <ColumnItem key={status.id} onDelete={statuses.request} onUpdate={statuses.request} status={status} changePosition={changePosition} columns={columns} />} />
					</Card>{' '}
					<br />
					<Card>
						<div className='columns'>
							<div className='is-size-4 column'>Кабинеты</div>

							{cp('academy.add_room') ? (
								<div className='column is-narrow'>
									<Button onClick={showRoomCreateModal} text='Добавить' icon='ion-md-add' className='is-pulled-right is-link is-outlined' />
								</div>
							) : null}
						</div>

						<Table loading={rooms.loading} items={rooms.response ? rooms.response.results : []} renderItem={room => <RoomItem key={room.id} room={room} onUpdate={rooms.request} onDelete={rooms.request} />} />
					</Card>
					<br />
					<Card>
						<div className='columns'>
							<div className='is-size-4 column'>Каникулы</div>

							{cp('academy.add_room') ? (
								<div className='column is-narrow'>
									<Button onClick={showHolidayCreateModal} text='Добавить' icon='ion-md-add' className='is-pulled-right is-link is-outlined' />
								</div>
							) : null}
						</div>

						<Table loading={holidays.loading} items={holidays.response ? holidays.response.results : []} renderItem={holiday => <HolidayItem key={holiday.id} holiday={holiday} onUpdate={holidays.request} onDelete={holidays.request} />} />
					</Card>
					<br />
					<Card>
						<div className='columns'>
							<div className='is-size-4 column'>Предметы</div>

							{cp('academy.add_room') ? (
								<div className='column is-narrow'>
									<Button onClick={showSubjectCreateModal} text='Добавить' icon='ion-md-add' className='is-pulled-right is-link is-outlined' />
								</div>
							) : null}
						</div>

						<Table loading={subjects.loading} items={subjects.response ? subjects.response.results : []} renderItem={subject => <SubjectItem key={subject.id} item={subject} onUpdate={subjects.request} onDelete={subjects.request} />} />
					</Card>
				</div>

				<div className='column'>
					<Card>
						<div className='columns'>
							<div className='is-size-4 column'>Каналы продаж</div>
							{cp('academy.add_advert') ? (
								<div className='column is-narrow'>
									<Button onClick={showSalesChanelCreateModal} text='Добавить' icon='ion-md-add' className='is-pulled-right is-link is-outlined' />
								</div>
							) : null}
						</div>

						{cp('academy.view_advert') ? <Table loading={salesChannels.loading} items={salesChannels.response ? salesChannels.response.results : []} renderItem={item => <SalesChannelItem key={item.id} onUpdate={salesChannels.request} onDelete={salesChannels.request} item={item} />} /> : null}
					</Card>

					<br />

					<Card>
						<div className='columns'>
							<div className='is-size-4 column'>Этика</div>
							{cp('academy.add_advert') ? (
								<div className='column is-narrow'>
									<Button onClick={showNoteCreateModal} text='Добавить' icon='ion-md-add' className='is-pulled-right is-link is-outlined' />
								</div>
							) : null}
						</div>

						{cp('academy.view_advert') ? <Table loading={notes.loading} items={notes.response ? notes.response.results : []} renderItem={item => <NoteItem key={item.id} onUpdate={notes.request} onDelete={notes.request} item={item} />} /> : null}
					</Card>
				</div>
			</div>
		</Layout>
	);
}
