import React from 'react';
import { useHistory } from 'react-router-dom';
import { GROUPS_DETAIL, STATUS_LIST, STUDENT_LIST } from '../urls';
import { useInfiniteScroll, useLoad } from '../hooks/request';
import Table from '../components/common/Table';
import StudentItem from '../components/StudentItem';
import { useQueryParams } from '../hooks/queryString';
import { checkForAdmin } from '../utils/auth';

export default function StudentListModal({ groupId }) {
	const isAdmin = checkForAdmin();
	const params = useQueryParams();
	const group = useLoad({ url: GROUPS_DETAIL.replace('{id}', groupId) });
	const history = useHistory();
	const status = useLoad({ url: STATUS_LIST });
	const statusList = status.response ? status.response.results : [];
	const students = useInfiniteScroll({ url: STUDENT_LIST, params: { group: groupId, state: params.state, ...params } }, []);
	const studentsList = students.response?.results || [];
	const activeStudentsList = studentsList.filter(item => !!item.isActive);

	// async function setUrl(key, value) {
	// 	const val = params[key] !== String(value) ? value : undefined;
	// 	history.push(`?${queryString.stringify({ ...params, [key]: val })}`);
	// }

	return (
		<div>
			<span className='is-size-4'>Студенты группы - {group.response ? group.response.name : ''}</span>
			<br />
			<br />
			{/* <div style={{ marginLeft: 8 }} className='tags'>
				{/* <span className='tag has-background-light-gray pointer' onClick={() => history.push(`/academy/group/${groupId}/students-list`)}>
					{isEmpty(params) ? <i className={cn('icon ion-md-checkmark')} /> : null}
					Все
				</span>
				&nbsp; */}
			{/* {statusList.map(item => (
					<div key={item.id}>
						<span style={{ background: item.color }} className='tag pointer' onClick={() => setUrl('state', item.id)}>
							{params.state === `${item.id}` ? <i className='icon ion-md-checkmark' /> : null}
							{item.title}
						</span>
						&nbsp;
					</div>
				))}
			</div> */}
			<Table
				style={{ paddingBottom: 100 }}
				// activePage={page}
				// onPageChange={setPage}
				columns={{
					index: '№',
					student: 'Имя',
					phone: 'Телефон',
					totalPiad: 'Оплачено',
					monthPaid: 'Опл. в этом месяце',
					balance: 'Баланс',
					actions: '',
				}}
				items={isAdmin ? activeStudentsList : activeStudentsList}
				// totalCount={students.response ? students.response.count : 0}
				renderItem={(item, index) => <StudentItem students={students} index={index} key={item.id} onSuccess={students.request} onPaymentCreate={students.request} item={item} />}
				children={
					<div ref={students.ref} className='has-text-grey-light is-italic has-text-centered'>
						{!students.hasMore && !students.loading && students.length !== 0 ? 'Загрузили всех студенты' : ''}
					</div>
				}
			/>
		</div>
	);
}
