import React from 'react'
import Button from './common/Button'

export const PermissionDeniedModal = ({ onCancel }) => (
    <div className="modal-backdrop">
        <div className="modal-container">
            <div className="modal-header">
                <h2 className="modal-title">Доступ запрещен</h2>
            </div>
            <div className="modal-body">
                <p className="modal-text">
                    У вас нет необходимых разрешений для доступа к этому контенту. Если вы считаете, что это ошибка,
                    обратитесь к администратору.
                </p>
            </div>
            <div className="modal-footer" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button text="Закрыть" className="close-button is-danger is-outlined" onClick={onCancel} />
            </div>
        </div>
    </div>
)
