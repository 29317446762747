import React from "react"
import { useHistory } from "react-router-dom"
import queryString from "query-string"
import cn from "classnames"
import { css, StyleSheet } from "aphrodite"
// import FilterByBranch from "./common/FilterByBranch"
import { useLoad } from "../hooks/request"
import { ROOM_LIST } from "../urls"

export default function RoomFilter({params}) {
    const roomsReq = useLoad({ url: ROOM_LIST })
    const roomsResults = roomsReq.response ? roomsReq.response.results : []
    const rooms = roomsResults.map(item => ({
        id: item?.id,
        name: item?.name,
        isMain: item?.isMain
    }))

    const history = useHistory()

    async function setUrl(key, value) {
        const val = params[key] !== String(value) ? value : undefined
        history.push(`?${queryString.stringify({ ...params, [key]: val })}`)
    }

    function handleRoomClick(roomId) {
        setUrl("room", roomId)
    }

    return (
        <main className={cn(css(styles.parent))}>
            <div style={{display:'flex', alignItems:'center', gap:'1rem', flexWrap:'wrap'}}>
                <span
                    className={cn("tag", "pointer", "is-light", {
                        "is-success": !params.room,
                        "is-medium": !params.room,
                    })}
                    onClick={()=> {
                    handleRoomClick()
                    }}>
                    {!params.room && <i className={cn("icon", "ion-md-checkmark", css(styles.checkmark))} />}
                    Все
                </span>
                {rooms.map((item) => (
                    <span
                    key={item.id}
                    className={cn("tag", "pointer", "is-light", {
                        "is-success": params.room == item.id,
                            "is-medium": params.room == item.id,
                        })}
                        onClick={() => handleRoomClick(item.id)}>
                        {params.room == item.id && <i className={cn("icon", "ion-md-checkmark", css(styles.checkmark))} />}
                        {item.name}
                    </span>
                ))}
            </div>

      {/*      <aside style={{flex:'auto', display:'flex', justifyContent:'flex-end'}}>
                <FilterByBranch />
            </aside>*/}
        </main>
    )
}

const styles = StyleSheet.create({
    parent: {
        display: "flex",
        alignItems: "center",
        gap: "1rem",
        flexWrap: "wrap",
    },
    checkmark: {
        marginRight: "3px",
    },
})
