/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-nested-ternary */
import cn from 'classnames';
import React, { useState } from 'react';
import { SALES_CHANNEL_DETAIL, SALES_CHANNEL_CHANGE_MAIN } from '../urls';
import { useDeleteRequest, usePutRequest } from '../hooks/request';
import { useModal } from '../hooks/modal';
import { checkPermission as cp } from '../utils/auth';
import { useMessage } from '../hooks/message';
import Loader from './common/Loader';
import SalesChannelUpdate from './SalesChannelUpdate';
import { PermissionDeniedModal } from './PermissionDeniedModal';

export default function SalesChannelItem({ item, onDelete, onUpdate }) {
	const salesChannelDelete = useDeleteRequest({ url: SALES_CHANNEL_DETAIL.replace('{id}', item.id) });
	const salesChannelChangeMain = usePutRequest();
	const [showDropdown, setShowDropdown] = useState(false);
	const isSuperAdmin = JSON.parse(localStorage.getItem('user')).isSuperuser;
	const [showPermissionDenied, hidePermissionDenied] = useModal(<PermissionDeniedModal onSuccess={() => hidePermissionDenied()} onCancel={() => hidePermissionDenied()} />);

	async function changeMain() {
		await salesChannelChangeMain.request({ url: SALES_CHANNEL_CHANGE_MAIN.replace('{id}', item.id) });
		onUpdate();
	}

	const [showUpdateModal, hideUpdateModal] = useModal(
		<SalesChannelUpdate
			salesChannel={item}
			onUpdate={() => {
				onUpdate();
				hideUpdateModal();
			}}
			onCancel={() => {
				hideUpdateModal();
			}}
		/>
	);

	const [showMessage] = useMessage();

	async function deleteSalesChannel() {
		if (global.confirm('Вы действительно хотите удалить?')) {
			const { error } = await salesChannelDelete.request();

			if (error) {
				showMessage(error.data.detail, 'is-danger');
				return;
			}
			await salesChannelDelete.request();
			onDelete();
		}
	}

	return (
		<tr>
			<td>
				{item.name}
				<span className='is-size-7 has-text-success'>{item.isMain ? ' (основной)' : ''}</span>
			</td>

			<td className='has-text-right'>
				{cp('academy.delete_advert') ? !salesChannelDelete.loading ? <i onClick={isSuperAdmin ? deleteSalesChannel : showPermissionDenied} className='icon pointer ion-md-trash' /> : <Loader className='icon' /> : null}

				{cp('academy.change_advert') ? <i onClick={showUpdateModal} className='icon pointer ion-md-create' /> : null}

				<div className={cn('dropdown is-right', { 'is-active': showDropdown })} onMouseLeave={() => setShowDropdown(false)}>
					<div className='dropdown-trigger pointer'>
						<i className='icon ion-md-more' onClick={() => setShowDropdown(!showDropdown)} />
					</div>

					<div className='dropdown-menu'>
						<div className='dropdown-content'>
							{cp('finance.change_wallet') && !item.isMain ? (
								<a className='dropdown-item' onClick={changeMain}>
									<i className='icon ion-md-checkmark' />
									Сделать главным
								</a>
							) : (
								<a className='dropdown-item'>
									<i className='icon ion-md-checkmark' />
									Главный
								</a>
							)}
						</div>
					</div>
				</div>
			</td>
		</tr>
	);
}
