import React from 'react'
import Layout from '../components/Layout'
import { useMessage } from '../hooks/message'
import { usePutRequest } from '../hooks/request'
import { CHANGE_COMPANY, CHANGE_PASSWORD, USER_SETTINGS } from '../urls'
import UserForm from '../components/UserForm'
import UserChangePassword from '../components/UserChangePassword'
import SettingsTabs from '../components/SettingsTabs'
import Card from '../components/common/Card'
import UserCompanyForm from '../components/UserCompanyForm'

export default function SettingsUser() {
    const updateUserSetting = usePutRequest({ url: USER_SETTINGS })
    const changePassword = usePutRequest({ url: CHANGE_PASSWORD })
    const changeCompany = usePutRequest({ url: CHANGE_COMPANY })
    const user = JSON.parse(localStorage.getItem('user'))
    const [showMessage] = useMessage()

    async function onSubmit(data) {
        await updateUserSetting.request({ data })
        localStorage.setItem('user', JSON.stringify(data))
        showMessage('Информация изменен', 'is-success')
    }

    async function onUpdateBranch(data) {
        await changeCompany.request({ data })
        localStorage.setItem('user', JSON.stringify(data))
        showMessage('Информация изменен', 'is-success')
    }

    async function updatePassword(data, actions) {
        if (data.newPassword !== data.confirmPassword) {
            showMessage('Введенные пароли не совпадают', 'is-danger')
            return
        }

        const { error } = await changePassword.request({ data, newPassword: data.newPassword })
        if (error) {
            showMessage('Введете старый пароль правилно.', 'is-danger')
        } else {
            showMessage('Пароль изменен', 'is-success')
        }
        actions.resetForm()
    }

    const isSuperAdmin = JSON.parse(localStorage.getItem('user')).isSuperuser

    return (
        <Layout className="has-background-light">
            <SettingsTabs />
            <div className="columns">
                <div className="column">
                    <Card>
                        <UserForm user={user} onSubmit={onSubmit} />
                    </Card>
                </div>&nbsp;

                <div className="column">
                    <Card>
                        <UserChangePassword onSubmit={updatePassword} />
                    </Card>
                </div>
            </div>
            {isSuperAdmin ? (
                <div className="columns">
                    <div className="column is-6">
                        <Card>
                            <UserCompanyForm loading={changeCompany.loading} user={user} onSubmit={onUpdateBranch} />
                        </Card>
                    </div>
                &nbsp;
                </div>
            ) : null}
        </Layout>
    )
}
