/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-nested-ternary */
import cn from 'classnames';
import React, { useState } from 'react';
import { useDeleteRequest, usePutRequest } from '../hooks/request';
import { useModal } from '../hooks/modal';
import { TRANSACTION_CATEGORY_DETAIL, TRANSACTION_CATEGORY_CHANGE_MAIN } from '../urls';
import { checkPermission as cp } from '../utils/auth';
import { useMessage } from '../hooks/message';
import Loader from './common/Loader';
import TransactionCategoryUpdate from './TransactionCategoryUpdate';
import { PermissionDeniedModal } from './PermissionDeniedModal';

export default function TransactionCategoryItem({ category, onDelete, onUpdate }) {
	const transactionCategoryDelete = useDeleteRequest({
		url: TRANSACTION_CATEGORY_DETAIL.replace('{id}', category.id),
	});
	const categoryChangeMain = usePutRequest();
	const [showDropdown, setShowDropdown] = useState(false);
	const isSuperAdmin = JSON.parse(localStorage.getItem('user')).isSuperuser;
	const [showPermissionDenied, hidePermissionDenied] = useModal(<PermissionDeniedModal onSuccess={() => hidePermissionDenied()} onCancel={() => hidePermissionDenied()} />);

	async function changeMain() {
		await categoryChangeMain.request({ url: TRANSACTION_CATEGORY_CHANGE_MAIN.replace('{id}', category.id) });
		onUpdate();
	}

	const [showUpdateModal, hideUpdateModal] = useModal(
		<TransactionCategoryUpdate
			category={category}
			onUpdate={() => {
				onUpdate();
				hideUpdateModal();
			}}
			onCancel={() => {
				hideUpdateModal();
			}}
		/>
	);

	const [showMessage] = useMessage();

	async function deleteCategory() {
		if (global.confirm('Вы действительно хотите удалить?')) {
			const { error } = await transactionCategoryDelete.request();
			if (error) {
				showMessage(error.data.detail, 'is-danger');
				return;
			}
			onDelete();
		}
	}

	return (
		<tr>
			<td>
				{category.name || <span className='is-italic has-text-grey'>не указан</span>} &nbsp;
				{category.systemName ? <i className='has-text-grey'>(Системная)</i> : null}
				<span className='is-size-7 has-text-success'>{category.isMain ? ' (основной)' : ''}</span>
			</td>

			<td>{category.type === 'income' ? <p className='has-text-success'>приход</p> : <p className='has-text-danger'>расход</p>}</td>

			<td width='100px' className='has-text-right'>
				{cp('finance.delete_transactioncategory') && !category.systemName ? !transactionCategoryDelete.loading ? <i onClick={() => (isSuperAdmin ? deleteCategory() : showPermissionDenied())} className='icon pointer ion-md-trash' /> : <Loader className='icon' /> : null}

				{cp('finance.change_transactioncategory') ? <i onClick={showUpdateModal} className='icon pointer ion-md-create' /> : null}

				<div className={cn('dropdown  is-right is-pulled-right', { 'is-active': showDropdown })} onMouseLeave={() => setShowDropdown(false)}>
					<div className='dropdown-trigger pointer'>
						<i className='icon ion-md-more' onClick={() => setShowDropdown(!showDropdown)} />
					</div>

					<div className='dropdown-menu'>
						<div className='dropdown-content'>
							{cp('finance.change_wallet') && !category.isMain ? (
								<a className='dropdown-item' onClick={changeMain}>
									<i className='icon ion-md-checkmark' />
									Сделать главным
								</a>
							) : (
								<a className='dropdown-item'>
									<i className='icon ion-md-checkmark' />
									Главный
								</a>
							)}
						</div>
					</div>
				</div>
			</td>
		</tr>
	);
}
