import isEmpty from 'lodash/isEmpty';
import React, { useMemo, useState } from 'react';
import { keyBy } from 'lodash';
import CustomerBoard from '../components/CustomerBoard';
import CustomersActionsBar from '../components/CustomersActionsBar';
import Layout from '../components/Layout';
import { useQueryParams } from '../hooks/queryString';
import { usePersistState } from '../hooks/state';
import CustomerTable from '../components/CustomerTable';
import { useInfiniteScroll2, useLoad } from '../hooks/request';
import { CUSTOMER_LIST, STATUS_LIST } from '../urls';
import { PermissionDenied } from '../components/PermissionDenied';
import { checkPermission as cp } from '../utils/auth';
import AcademyHeader from '../components/AcademyHeader';

export default function Customers() {
	const [searchParams, setSearchParams] = useState({});
	const [view, setView] = usePersistState('customersView', 'board'); // or table
	const queryParams = useQueryParams();
	const params = { type: 'active', ...queryParams, ...searchParams };
	const customers = useInfiniteScroll2({ url: CUSTOMER_LIST, params }, [searchParams]);
	const status = useLoad({ url: STATUS_LIST });
	const statusList = status.response?.results || [];
	// convert `[{ id: 1 }]` to {1: { id: 1 }}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const statsObj = useMemo(() => keyBy(statusList, 'id'), [statusList.length]);

	async function onUpdate(customerId, data) {
		const results = isEmpty(data) ? await customers.response.results.filter(item => item.id !== customerId) : await customers.response.results.map(item => (item.id === customerId ? { ...item, ...data } : item));
		customers.setResponse({ count: customers.response.count, results });
	}

	if (!cp('academy.view_customer')) {
		return <PermissionDenied />;
	}

	return (
		<Layout className={view !== 'table' ? 'has-background-light' : null}>
			<AcademyHeader />

			<CustomersActionsBar customers={customers} view={view} onViewChange={setView} statusList={statusList} searchParams={searchParams} setSearchParams={setSearchParams} />

			{view === 'table' ? <CustomerTable type={params.type} statsObj={statsObj} onUpdate={onUpdate} statuses={statusList} customers={customers} /> : <CustomerBoard columns={statusList} type={params.type} searchParams={searchParams} />}
		</Layout>
	);
}
