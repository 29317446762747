export const SIGNIN = '/users/sign-in'
export const SIGNUP = '/users/sign-up'
export const SIGNOUT = '/users/sign-out'
export const USER_SETTINGS = '/users/user_settings'
export const CHANGE_PASSWORD = '/users/change_password'
export const CHANGE_COMPANY = '/users/change_company'
export const CONFIRM = '/users/confirm'
export const RESET_PASSWORD = '/users/reset_password'
export const RESET_LINK = '/users/reset_link'

export const USERS_LIST = '/users/user'

export const REFRESH = '/users/refresh'

export const GROUPS_LIST = '/academy/group/'
export const GROUPS_TIMELINE_SYNC = '/academy/group-sync/'
export const GROUPS_DETAIL = '/academy/group/{id}'
export const GROUPS_ARCHIVE = '/academy/group/archive/{id}'

export const SALES_CHANNEL_LIST = 'academy/sales_channel/'
export const SALES_CHANNEL_DETAIL = 'academy/sales_channel/{id}'
export const SALES_CHANNEL_CHANGE_MAIN = '/academy/sales_channel/change_main/{id}'

export const ROOM_LIST = '/academy/room/'
export const ROOM_DETAIL = '/academy/room/{id}'
export const ROOM_CHANGE_MAIN = '/academy/room/change_main/{id}'

export const STUDENT_LIST = '/academy/student/'
export const STUDENT_DETAIL = '/academy/student/{id}'

export const CUSTOMER_LIST = '/academy/customer/'
export const CUSTOMER_DEBTORS = '/academy/customer_debtors/'
export const CUSTOMER_DETAIL = '/academy/customer/{id}'
export const CUSTOMER_IMAGE = '/academy/customer-image/{id}'
export const CUSTOMER_ASSESSMENT = '/academy/customer-assessments/{customerId}'
export const CUSTOMER_MOVE = '/academy/customer/{id}/move'

export const STATUS_LIST = '/academy/status/'
export const STATUS_DETAIL = '/academy/status/{id}'
export const STATUS_MOVE = '/academy/status/{id}/move'

export const TRANSACTION_LIST = '/finance/transaction/'
export const TRANSACTION_DETAIL = '/finance/transaction/{id}'
export const TRANSACTION_CATEGORY_LIST = '/finance/transaction-category/'
export const TRANSACTION_CATEGORY_DETAIL = '/finance/transaction-category/{id}'
export const TRANSACTION_CATEGORY_CHANGE_MAIN = '/finance/transaction-category/change_main/{id}'
export const TRANSACTION_TRANSFER = '/finance/transaction-transfer/{id}'

export const WALLET_LIST = '/finance/wallet/'
export const WALLET_SIMPLE_LIST = '/finance/wallet-simple-list/'
export const WALLET_DETAIL = '/finance/wallet/{id}'
export const WALLET_CHANGE_MAIN = '/finance/wallet/change_main/{id}'

export const EMPLOYEE_LIST = '/staff/employee/'
export const EMPLOYEE_DETAIL = '/staff/employee/{id}'
export const EMPLOYEE_SIMPLE_LIST = '/staff/employee-simple-list/'
export const EMPLOYEE_SALARY = '/staff/employee-salary/{id}'
export const EMPLOYEE_SALARY_HISTORY = '/staff/employee-salary/{id}/history'
export const EMPLOYEE_INVITATION = '/staff/employee/{id}/invite'
export const EMPLOYEE_INVITATION_SIGNUP = '/staff/employee-sign-up/{code}'

export const PAYMENT_STUDENT = '/finance/student_payment/{id}'
export const SALARY_LIST = 'finance/salary/{employee_id}'

// export const TASK_LIST = 'task/task/'
// export const TASK_DETAIL = 'task/task/{id}'
export const GENERAL = 'core/general/'
export const STATISTICS_SALES_CHANNEL = 'stats/sales_channel'
export const STATISTICS_SALE = 'stats/sale'
export const STATISTICS_OUTCOME = 'stats/outcome'
export const STATISTICS_FINANCE = 'stats/finance'
export const STATISTICS_COST_ADVERT = 'stats/cost_advert'
export const STATISTICS_SALES_FUNNEL = 'stats/sales_funnel'
export const BRANCH_LIST = '/academy/branch'
export const BRANCH_DETAIL = 'academy/branch/{id}'
export const MESSAGE_LIST = 'academy/message'
export const ALL_MESSAGE = 'academy/all_student_message'
export const MESSAGE_DETAIL = '/academy/message/{id}'
export const STUDENT_MESSAGE = 'academy/student_message/{id}'
export const STUDENT_MESSAGE_DETAIL = '/academy/student_message/{id}'
export const EMPLOYEE_TIMELINE = '/staff/employee/timeline/'
export const ASSESSMENT_LIST = '/academy/assessment'
export const ASSESSMENT_DETAIL = '/academy/assessment/{id}'
export const ASSESSMENT_ITEM_LIST = '/academy/assessment-item'
export const ASSESSMENT_UPDATE = '/academy/assessment-update/{id}'
export const ASSESSMENT_ITEM_DETAIL = '/academy/assessment-item/{id}'
export const STUDENT_PAYMENT_DATE_LIST = '/academy/student_payment_date/'
export const STUDENT_PAYMENT_DATE_DETAIL = '/academy/student_payment_date/{id}'
export const NOTE_LIST = '/academy/note/'
export const NOTE_DETAIL = '/academy/note/{id}'
export const DASHBOARD = '/academy/assessment_dashboard/{groupId}'
export const NOTE_ITEM_LIST = '/academy/note_item/'
export const NOTE_ITEM_DETAIL = '/academy/note_item/{id}'
export const HOLIDAY_LIST = '/academy/holiday/'
export const HOLIDAY_DETAIL = '/academy/holiday/{id}'

export const LEADS_LIST = '/academy/leads/'
export const LEADS_DETAIL = '/academy/leads/{id}'
export const SUBJECT_LIST = '/academy/subject/'
export const SUBJECT_DETAIL = '/academy/subject/{id}'


export const BOARD_LIST = 'tasks/board/'
export const BOARD_DETAIL = 'tasks/board/{id}'

export const TASK_LIST = 'tasks/tasks/'
export const TASK_DETAIL = 'tasks/tasks/{id}'
export const COMMENT_LIST = 'tasks/comment/'
export const COMMENT_DETAIL = 'tasks/comment/{id}'
export const MERGE_REQUEST = 'tasks/tasks/{id}/merge-request'
export const CUSTOMER_EXPORT = 'academy/customer/export'
export const FILE_UPLOAD = '/academy/file-upload'
export const EMPLOYEE_ARCHIVE = '/staff/employee/archive/{id}'


export const BOARD_TASK_LIST = 'tasks/board/{board_id}/board_task/'
export const BOARD_TASK_DETAIL = 'tasks/board/{board_id}/board_task/{id}'
export const BOARD_TASK_MOVE = 'tasks/board/{board_id}/board_task/{id}/move'
